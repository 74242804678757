/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
// import { format, isValid, parse } from 'date-fns';
import React from 'react';
import { format, isValid, parse } from 'date-fns';
import { useMantineColorScheme } from '@mantine/core';
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { getMonthInfo, renderNumber } from '../../../utilities/general';
import styles from './CompanyDataV2.module.css';

export default function StakedBarChart(props) {
  const {
    chartName, days, data,
  } = props;
  const { colorScheme } = useMantineColorScheme();

  const DateFormatter = (date) => {
    const d = parse(date, 'yyyy-MM-dd', new Date());
    return isValid(d) ? format(d, 'LLL dd') : null;
  };

  const prioritizeBarKeys = (availableKeys, priorityKeys) => {
    const prioritized = priorityKeys.filter((key) => availableKeys.includes(key));

    const remaining = availableKeys.filter((key) => !priorityKeys.includes(key));

    return [...prioritized, ...remaining];
  };

  const priorityKeys = ['SOL', 'SUI', 'ETH'];

  const barKeys = Array.from(
    new Set(data?.flatMap((item) => item.assets?.map((value) => value?.asset))),
  );

  const reorderedBarKeys = prioritizeBarKeys(barKeys, priorityKeys);
  const sortedBarKeys = reorderedBarKeys.sort(
    (a, b) => priorityKeys.indexOf(a) - priorityKeys.indexOf(b),
  );

  const colors = ['#0072C3', '#33B1FF', '#F9A61A', '#ff0000', '#00ff00'];
  const priorityAssets = ['SOL', 'SUI', 'ETH'];
  const colorMap = new Map();

  const getColorForAsset = (assetName) => {
    if (!colorMap.has(assetName)) {
      let color;

      const priorityIndex = priorityAssets.indexOf(assetName);
      if (priorityIndex !== -1) {
        color = colors[priorityIndex % colors.length];
      } else {
        color = colors[(colorMap.size + priorityAssets.length) % colors.length];
      }

      colorMap.set(assetName, color);
    }

    return colorMap.get(assetName);
  };

  const prioritizeAssetKeys = (assets, priorityAssetKey) => {
    const prioritized = priorityKeys
      .map((key) => assets.find((asset) => asset.asset === key))
      .filter(Boolean);

    const remaining = assets.filter(
      (asset) => !priorityAssetKey.includes(asset.asset),
    );

    return [...prioritized, ...remaining];
  };

  const processAssets = (assetData, priorityAssetKeys) => {
    const initialResult = { assetMap: new Map(), uniqueAssets: [] };
    const result = assetData?.reduce(
      (acc, entry) => {
        entry.assets.forEach((asset) => {
          if (!acc.assetMap.has(asset.asset)) {
            const color = getColorForAsset(asset.asset);
            acc.assetMap.set(asset.asset, {
              asset: asset.asset,
              icon: asset.icon,
              color,
            });
            acc.uniqueAssets.push({
              asset: asset.asset,
              icon: asset.icon,
              color,
            });
          }
        });
        return acc;
      }, initialResult,
    );

    if (!result || !result.uniqueAssets) {
      return [];
    }

    result.uniqueAssets = prioritizeAssetKeys(result.uniqueAssets, priorityAssetKeys);

    return result?.uniqueAssets;
  };

  const reorderedAssets = processAssets(data || [], priorityKeys);

  const reorderedAssetMap = Object.fromEntries(reorderedAssets.map((reorderedAsset) => [reorderedAsset.asset, reorderedAsset.color]));
  // Create the new color array based on the order
  const fallbackColors = priorityKeys.filter((asset) => reorderedAssetMap[asset]).map((asset) => reorderedAssetMap[asset]);

  const CustomTooltip = (propss) => {
    const { active, payload } = propss;
    const assetValue = payload?.map((val) => val?.payload?.date);
    const {
      day, daySuffix, month, year,
    } = getMonthInfo(assetValue[0]);
    if (active && payload && payload.length) {
      return (
        <div className="p-4 text-sm bg-white border-gray-400 rounded-xl shadow-xl border w-auto flex flex-col gap-2 justify-center">
          <div className="flex flex-col gap-2">
            <div className="text-base font-normal text-[#58667E]">
              <span>{day}</span>
              <sup>{daySuffix}</sup>
              <span>{` ${month}, ${year}`}</span>
            </div>
            {payload?.map((entry, index) => entry?.payload?.assets
              ?.sort((a, b) => priorityKeys.indexOf(a.asset) - priorityKeys.indexOf(b.asset))
              ?.map((val, i) => (
                index === 0 && (
                  <div
                    key={i}
                    style={{ color: getColorForAsset(val.asset) }}
                  >
                    <span>{`${val?.asset} : $${renderNumber(val?.value)}`}</span>
                  </div>
                )
              )))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={`${styles.paddingLeft19} flex flex-col py-3 px-3 h-full`}>
      <div className="flex justify-between">
        <div className="pt-0">
          <div className={`${styles.HeadingText} ${colorScheme === 'dark' ? 'text-[#beb9b9]' : 'text-[#3A3A3A]'} flex items-baseline gap-1`}>
            {`${chartName}`}
            <span className="text-xs opacity-70 flex !items-center">{` - ${days}`}</span>
          </div>
        </div>
        <div className="flex gap-2">
          {reorderedAssets?.map((asset) => (
            <div
              key={asset.asset}
              className="flex w-7 h-7 rounded-full justify-center items-center"
              style={{ backgroundColor: asset.color }}
            >
              <div className={`flex w-5 h-5 rounded-full ${colorScheme === 'dark' ? 'bg-black' : 'bg-white'} justify-center items-center`}>
                <img
                  className="w-4 h-4 rounded-full"
                  src={`${asset.icon}`}
                  alt="ETH"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-[100%] h-full mt-5">
        <ResponsiveContainer width="99%" height={175}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey="date"
              tickLine={false}
              tickFormatter={DateFormatter}
              tickSize={6}
              interval={days === '30 Days' ? 5 : 'preserveStartEnd'}
              style={{ fontSize: 10 }}
              stroke="#A3AED0"
              axisLine={false}
              padding={{ left: 10, right: 5 }}
            />
            <Tooltip cursor={false} content={<CustomTooltip />} />
            {sortedBarKeys.map((key, index) => (
              <Bar
                key={key}
                dataKey={(entry) => {
                  const valueObj = entry?.assets?.find((v) => v?.asset === key);
                  return valueObj ? valueObj?.value : 0;
                }}
                stackId="a"
                // fill={['#0072C3', '#33B1FF', '#F9A61A', '#ff0000', '#00ff00'][index % 5]}
                fill={fallbackColors[index % reorderedAssets.length]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
